import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';
import t from '@jetshop/intl';
import { theme } from '../Theme';

export const baseButtonStyles = `
  background-color: ${theme.colors.red};
  color: ${theme.colors.white};
  border: 1px solid ${theme.colors.red};

  font-size: 0.875rem;
  font-weight: bold;

  border-radius: 2.5rem;
  box-sizing: border-box;
  padding: 0.8125rem 2rem 0.8125rem 2rem;

  ${theme.below.md} {
    max-width: 100%;
  }

  svg path {
    fill: ${theme.colors.red};
  }

  :hover {
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.darkgrey};
    color: ${theme.colors.darkgrey};

    svg path {
      fill: ${theme.colors.darkgrey};
    }

    circle.path {
      stroke: black;
    }
  }

  &.secondary {
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.darkgrey};
    color: ${theme.colors.darkgrey};
    circle.path {
      stroke: black;
    }

    :hover {
      background-color: ${theme.colors.red};
      color: ${theme.colors.white};
      border: 1px solid ${theme.colors.red};

      svg path {
        fill: ${theme.colors.white};
      }
    }
  }

  &.disabled, :disabled {
    color: ${theme.colors.black};
    background-color: ${theme.colors.lightgrey};
    border: 1px solid ${theme.colors.lightgrey};

    svg path {
      fill: ${theme.colors.black};
    }

    :hover {
      color: ${theme.colors.black};
      background-color: ${theme.colors.lightgrey};
      border: 1px solid ${theme.colors.lightgrey};

      svg path {
        fill: ${theme.colors.black};
      }
    }
  }

  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
  line-height: 100%;
  > * {
    display: inline-block;
    vertical-align: text-bottom;
    line-height: normal;
  }
`;

export const baseLinkStyles = `
  color: ${theme.colors.black};
  background-color: inherit;
  text-decoration: underline;

  :hover {
    color: ${theme.colors.red};
  }
`;

export const Button = styled('button')`
  ${baseButtonStyles};
`;

export const ButtonNoAction = styled('div')`
  ${baseButtonStyles};
`;

export const ButtonLink = styled(Link)`
  ${baseButtonStyles};
  text-decoration: none;
`;

export const LinkText = styled(Link)`
  ${baseLinkStyles}
`;

export const ButtonThatLooksLikeALink = styled('button')`
  ${baseLinkStyles}
`;

export const ButtonExternal = ({ children, ...aProps }) => {
  const ButtonA = styled('a')`
    ${baseButtonStyles};
    text-decoration: none;
  `;
  return (
    <ButtonA {...aProps} target="_blank" rel="noreferrer">
      {children}
    </ButtonA>
  );
};

export const ButtonInternal = ({ children, ...aProps }) => {
  const ButtonA = styled(Link)`
    ${baseButtonStyles};
    text-decoration: none;
  `;
  return <ButtonA {...aProps}>{children}</ButtonA>;
};

export const ButtonAuto = (props) => {
  const { children, linkText, className } = props;

  if (linkText && typeof linkText === 'string') {
    if (linkText.indexOf('http') === 0) {
      return (
        <ButtonExternal href={linkText} className={className}>
          {children}
        </ButtonExternal>
      );
    } else if (linkText.indexOf('/') === 0) {
      return (
        <ButtonLink to={linkText} className={className}>
          {children}
        </ButtonLink>
      );
    }
  }
  return children;
};

export const AreaAuto = (props) => {
  const { children, linkText, ...aProps } = props;

  const StyledA = styled('a')`
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
  `;

  if (linkText && typeof linkText === 'string') {
    if (linkText.indexOf('http') === 0) {
      return (
        <StyledA href={linkText} target="_blank" rel="noreferrer" {...aProps}>
          {children}
        </StyledA>
      );
    } else if (linkText.indexOf('/') === 0) {
      return (
        <Link to={linkText} {...aProps}>
          {children}
        </Link>
      );
    }
  }
  return children;
};

const StyledSpinner = styled(Spinner)`
  height: 25px;
  circle.path {
    stroke: ${theme.colors.white};
  }
`;

export const ButtonWithLoading = ({
  loading,
  loadingText = t('Hold on a moment...'),
  secondary,
  ...props
}) =>
  loading ? (
    <Button
      {...props}
      className={cx(props.className, secondary && 'secondary')}
    >
      <span>{loadingText}</span>
      <StyledSpinner />
    </Button>
  ) : (
    <Button
      {...props}
      className={cx(props.className, secondary && 'secondary')}
    />
  );
